import React, { useRef } from 'react'

import styles from '../../../Styles/starting_page.module.css';

function WeatherDetails(props) {

    //since data.hourly always returns data for the next 48 hours, we need to splice the array
    //but we only want to have the data until 06:00AM of the next morning. therefore we need to get the first hour and then calculate how many are left until it's 06:00AM

    let date = new Date(props.data.hourly[0].dt * 1000);
    let hourlyAmount = 31 - date.getHours();

    return (
        <div className={styles.weatherClick} >
            <p className={styles.title}>Heute:</p>
            <div className={styles.vScroll}>
                {props.data.hourly.slice(0, hourlyAmount).map((item, index) => {
                    return <WeatherDetailsItem key={index} date={item.dt} hours iconCode={item.weather[0].icon} weatherDescr={item.weather[0].description} temp={Math.round(item.temp) + "°"} />
                })}
            </div>
            <p className={styles.title}>Nächsten 7 Tage:</p>
            <div className={styles.vScroll}>
                {props.data.daily.map((item, index) => {
                    return <WeatherDetailsItem key={index} date={item.dt} dayOfMonth iconCode={item.weather[0].icon} weatherDescr={item.weather[0].description} temp={Math.round(item.temp.day) + "°"} />
                })}
            </div>
        </div>
    )
}

function WeatherDetailsItem(props) {
    const WEATHER_ICON_SRC_PATH = "/Assets/images/";
    let iconSrc = useRef("clear_sky_d.svg");
    let title = "";

    switch (props.iconCode) {
        case "01d":
            iconSrc = "clear_sky_d.svg";
            break;
        case "01n":
            iconSrc = "clear_sky_n.svg";
            break;
        case "02d":
            iconSrc = "few_clouds_d.svg";
            break;
        case "02n":
            iconSrc = "few_clouds_n.svg";
            break;
        case "03d":
            iconSrc = "scattered_clouds.svg";
            break;
        case "03n":
            iconSrc = "scattered_clouds.svg";
            break;
        case "04d":
            iconSrc = "broken_clouds.svg";
            break;
        case "04n":
            iconSrc = "broken_clouds.svg";
            break;
        case "09d":
            iconSrc = "shower_rain.svg";
            break;
        case "09n":
            iconSrc = "shower_rain.svg";
            break;
        case "10d":
            iconSrc = "rain_d.svg";
            break;
        case "10n":
            iconSrc = "rain_n.svg";
            break;
        case "11d":
            iconSrc = "thunderstorm.svg";
            break;
        case "11n":
            iconSrc = "thunderstorm.svg";
            break;
        case "13d":
            iconSrc = "snow.svg";
            break;
        case "13n":
            iconSrc = "snow.svg";
            break;
        case "50d":
            iconSrc = "mist.svg";
            break;
        case "50n":
            iconSrc = "mist.svg";
            break;

        default:
            console.error("unknown icon code: " + props.iconCode);
            break;
    }

    let date = new Date(props.date * 1000);
    if (props.hours) {
        title = pad_number(date.getHours()) + ":00";
    }
    if (props.dayOfMonth) {
        let days = ["So. ", "Mo. ", "Di. ", "Mi. ", "Do. ", "Fr. ", "Sa. "];
        title = days[date.getDay()] + pad_number(date.getDate()) + "." + pad_number(date.getMonth() + 1 + ".");
    }

    return <div className={[styles.momentumText, styles.weatherContainer, styles.item].join(' ')}>
        <p id={styles.weatherDescription}>{title}</p>
        <div>
            <img id={styles.weatherIcon}
                src={WEATHER_ICON_SRC_PATH + iconSrc} alt={"icon" + props.weatherDescr}></img>
            <span id={styles.weatherTemp}>{props.temp}</span>
        </div>
        <p id={styles.weatherDescription}>{props.weatherDescr}</p>
    </div>
}

function pad_number(number) {
    if (number < 10) {
        return "0" + number;
    } else {
        return "" + number;
    }
}

export default WeatherDetails
