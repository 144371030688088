import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { GAuthContext } from '../App';

function Header() {

    const [dialog, setDialog] = useState(false);
    const [dialogMenu, setDialogMenu] = useState("");

    useEffect(() => {
        const script = document.createElement("script");
        script.src = '/Scripts/nav.js';
        script.async = true;
        document.body.appendChild(script);
    }, []);

    const clickItem = function (item) {
        setTimeout(() => {
            setDialogMenu(item);
            setDialog(true);
        }, 100);
    }

    function dialogBlur() {
        setTimeout(() => {
            setDialog(false);
        }, 100);
    }

    return (
        <header>
            <Link to="/" className="header_logo">Jonas Schiano</Link>

            <img src="/Assets/images/Hamburger Menu.svg" alt="Menu" className="header_toggle" id="nav-toggle" />

            <nav id="nav-menu">
                <div className="nav_content">
                    <img src="/Assets/images/Close Menu.svg" alt="Close Menu" className="nav_close" id="nav-close" />

                    <div className="nav_perfil">
                        <div className="nav_img">
                            <Link to="/"><img src="/Assets/images/Logo Small 2.svg" alt="Logo" className="nav_link" /></Link>
                        </div>

                        <div style={{ display: "none" }}>
                            <Link to="/" className="nav_name nav_link">Jonas Schiano</Link>
                            <span className="nav_profession">Developer</span>
                        </div>
                    </div>

                    <div className="nav_menu">
                        <ul className="nav_list">
                            <li className="nav_item"><NavLink to="/projekte" className="nav_link">Meine Projekte</NavLink></li>
                            <li className="nav_item"><NavLink to="/lebenslauf" className="nav_link">Lebenslauf</NavLink></li>
                            <li className="nav_item"><NavLink to="/staerken" className="nav_link">Stärken</NavLink></li>
                            <li className="nav_item"><NavLink to="/ueber-mich" className="nav_link">Über mich</NavLink></li>
                            <li className="nav_item"><NavLink to="/kontakt" className="nav_link">Kontakt</NavLink></li>
                        </ul>
                    </div>

                    <div className="nav_socials">
                        <div className="nav_social-icon" onClick={() => clickItem("notifications")} ><img src="/Assets/images/Notifications.svg"
                            alt="Notification-Icon" /></div>
                        <div className="nav_social-icon" style={{ position: "relative", left: "7px" }} onClick={() => clickItem("settings")} ><img
                            src="/Assets/images/Settings.svg" alt="Settings-Icon" /></div>
                        <div className="nav_social-icon" style={{ position: "relative", top: "14px" }} onClick={() => clickItem("account")} ><img
                            src="/Assets/images/Avatar.svg" alt="User-Icon" /></div>

                        {dialog && <HeaderDialog menu={dialogMenu} blur={dialogBlur} />}
                    </div>
                </div>
            </nav>

        </header>
    );
}

function HeaderDialog(props) {

    const dialogRef = useRef();

    const gAuth = useContext(GAuthContext);

    useEffect(() => {
        dialogRef.current.focus();
    }, [])

    const menu = props.menu;

    const items_notification = <ul>
        <HeaderDialogItem link="#" name="Keine Benachrichtigungen" />
        {gAuth.authorized && <hr />}
        {gAuth.authorized && <HeaderDialogItem link="/testing-page" name="Tests" />}
        {gAuth.authorized && <HeaderDialogItem link="/starting-page" name="Startseite" />}
    </ul>;
    const items_settings = <ul>
        <HeaderDialogItem link="#" name="Noch nicht implementiert" />
        <HeaderDialogItem link="#" name="Komme später wieder" />
        <HeaderDialogItem link="#" name="Einstellungen" />
        <hr />
        <HeaderDialogItem link="#" name="Datenschutz" />
    </ul>;
    const items_account = <ul>
        <HeaderDialogItem link="#" name="Profil" />
        <hr />
        <HeaderDialogItem link="#" name="Anmelden" disabled={gAuth.loggedIn} onClick={() => gAuth.signIn()} />
        <HeaderDialogItem link="#" name="Abmelden" disabled={!gAuth.loggedIn} onClick={() => gAuth.signOut()} />
    </ul>;

    var items;

    switch (menu) {
        case "notifications":
            items = items_notification;
            break;
        case "settings":
            items = items_settings;
            break;
        case "account":
            items = items_account;
            break;
        default:
            console.error("Couldn't open the Header Dialog. Unknown parameter: " + menu);
            break;
    }

    return (
        <div className="header_dialog" ref={dialogRef} tabIndex="-1" onBlur={props.blur}>
            {items}
        </div>
    );
};

function HeaderDialogItem(props) {
    function isFunction(functionToCheck) {
        return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
    }

    var onClick = null;
    if (!props.disabled && isFunction(props.onClick)) {
        onClick = props.onClick;
    }

    return <li className={props.disabled ? "disabled" : ""} > <Link to={props.link} className="nav_link" onClick={onClick}>{props.name}</Link></ li>
}

export default Header;