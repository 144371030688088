import React from 'react';

import styles from '../../Styles/no_site_match.module.css';

function NoSiteMatch() {
    return <div className={styles.noSiteMatchContainer}>
        <h2>Error 404</h2>
    </div>
}

export default NoSiteMatch;