import React, { useState, useEffect, useRef } from 'react';
import WeatherDetails from './Components/WeatherDetails';

import styles from '../../Styles/starting_page.module.css';

//Dummy-Data for testing
// eslint-disable-next-line
import Data from '../../DummyData/weather';

function StartingPage() {

    // eslint-disable-next-line
    const WEATHER_API_URL = "https://api.openweathermap.org/data/2.5/onecall?lon=9.7332&lat=52.3705&appid=fb8f755efb95956da236474db4b43141&units=metric&lang=de&exclude=minutely";
    const WEATHER_ICON_SRC_PATH = "/Assets/images/";

    const [time, setTime] = useState("00:00");
    const [date, setDate] = useState("01.01.1971");
    const [iconsrc, setIconsrc] = useState("clear_sky_d.svg");
    const [temp, setTemp] = useState(99);
    const [weatherdescr, setWeatherdescr] = useState("Keine Daten verfügbar.");
    const [tooltiptext, setTooltiptext] = useState("Keine Daten verfügbar");
    const [weatherHover, setWeatherHover] = useState(false);
    const [weatherClick, setWeatherClick] = useState(false);

    const data = useRef({});

    useEffect(() => {
        var timerID = setInterval(() => updateTime(), 1000);

        const DATE_OPTIONS = { weekday: 'long', year: 'numeric', month: '2-digit', day: '2-digit' };
        setDate(new Date().toLocaleDateString('de-DE', DATE_OPTIONS));

        // Comment this in when you go live.Comment this out when you test locally
        fetch(WEATHER_API_URL)
            .then((response) => response.json())
            .then(function (dataRes) {
                console.debug(dataRes);
                updateWeather(dataRes);
                data.current = dataRes
            })
            .catch(function (error) {
                console.error(error);
            });

        // Comment this in when you test. Comment this out when you go live
        // console.debug("This Data is not live Data.")
        // console.debug(Data);
        // updateWeather(Data);
        // data.current = Data;

        return () => {
            clearInterval(timerID);
        }
    }, []);

    function updateTime() {
        let current_date = new Date();
        setTime(pad_number(current_date.getHours()) + ":" + pad_number(current_date.getMinutes()));
    }

    function updateWeather(data) {
        setTemp(Math.round(data.current.temp) + "°");
        setWeatherdescr("Hannover");
        setTooltiptext(data.current.weather[0].description);

        switch (data.current.weather[0].icon) {
            case "01d":
                setIconsrc("clear_sky_d.svg");
                break;
            case "01n":
                setIconsrc("clear_sky_n.svg");
                break;
            case "02d":
                setIconsrc("few_clouds_d.svg");
                break;
            case "02n":
                setIconsrc("few_clouds_n.svg");
                break;
            case "03d" || "03n":
                setIconsrc("scattered_clouds.svg");
                break;
            case "04d" || "04n":
                setIconsrc("broken_clouds.svg");
                break;
            case "09d" || "09n":
                setIconsrc("shower_rain.svg");
                break;
            case "10d":
                setIconsrc("rain_d.svg");
                break;
            case "10n":
                setIconsrc("rain_n.svg");
                break;
            case "11d" || "11n":
                setIconsrc("thunderstorm.svg");
                break;
            case "13d" || "13n":
                setIconsrc("snow.svg");
                break;
            case "50d" || "50n":
                setIconsrc("mist.svg");
                break;

            default:
                break;
        }
    }

    function handlePopupClick() {
        setWeatherHover(false);
        setWeatherClick(!weatherClick);
    }

    function handlePopupEnter() {
        setTimeout(function () {
            if (!weatherClick) {
                setWeatherHover(true);
            }
        }, 150);
    }

    function handlePopupLeave() {
        setTimeout(function () {
            setWeatherHover(false);
        }, 150);
    }

    return <React.Fragment>
        <div className={styles.backgroundImage}></div>
        <div className={styles.bimgOverlay}></div>
        <div className={styles.container}>
            <div className={styles.momentumText} id={styles.momentumTime}>
                {time}
            </div>
            <div className={styles.momentumText} id={styles.momentumDate}>
                {date}
            </div>
        </div>
        <div className={[styles.momentumText, styles.weatherContainer].join(' ')}
            onClick={handlePopupClick}
            onMouseEnter={handlePopupEnter}
            onMouseLeave={handlePopupLeave}>
            <div>
                <img id={styles.weatherIcon}
                    src={WEATHER_ICON_SRC_PATH + iconsrc} alt={"icon" + weatherdescr}></img>
                <span id={styles.weatherTemp}>{temp}</span>
            </div>
            <p id={styles.weatherDescription}>{weatherdescr}</p>
        </div>
        {weatherHover && <p className={styles.weatherHover}>{tooltiptext}</p>}
        {weatherClick && <WeatherDetails data={data.current} />}
    </React.Fragment>
}

function pad_number(number) {
    if (number < 10) {
        return "0" + number;
    } else {
        return "" + number;
    }
}

export default StartingPage;