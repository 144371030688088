import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Components
import Header from './Components/Header';
import PrivateRoute from './Components/PrivateRoute';

//Pages
import NoSiteMatch from './Pages/NoSiteMatch';
import StartingPage from './Pages/StartingPage';
import TestPage from './Pages/TestPage';

export const GAuthContext = React.createContext();

function App() {

  //Google API Vars
  var auth2;
  var user;

  const [loggedIn, setLoggedIn] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    //Loads the Google API - Script
    insertGapiScript();
  });

  function insertGapiScript() {
    const script = document.createElement('script');
    script.src = "https://apis.google.com/js/api.js"
    script.onload = () => {
      initializeGoogleAPIs();
    }
    document.body.appendChild(script);
  };

  function initializeGoogleAPIs() {
    window.gapi.load("auth2", () => {
      auth2 = window.gapi.auth2.init({
        client_id: "65226209818-j38f6mboud58u78egfqp0lolk7edim3l.apps.googleusercontent.com"
      });

      auth2.then(() => {
        if (auth2.isSignedIn.get()) {
          user = auth2.currentUser.get();
          setLoggedIn(true);
          checkIfAuthorized();
        }
        setLoading(false);
        auth2.isSignedIn.listen(loginListener);
      });
    });
  }

  function checkIfAuthorized() {
    if (user.getBasicProfile().getEmail() === "jonasschiano60@gmail.com") {
      setAuthorized(true);
      document.title = "Schiano"
    } else {
      signOut();
      window.alert("\nAchtung!\n\nDiese Website befindet sich noch im Aufbau und kann deshalb noch nicht von jedem in vollem Umfang genutzt werden.");
    }
  }

  function signIn() {
    auth2.then(() => {
      var options = {
        redirect_uri: "/starting-page"
      };
      auth2.signIn(options).then(() => {
        user = auth2.currentUser.get();
        checkIfAuthorized();
      });
    });
  }

  function signOut() {
    auth2.then(() => {
      auth2.signOut();
      setAuthorized(false);
    })
  }

  function loginListener(param) {
    setLoggedIn(param);
  }

  var authObj = {
    auth2: auth2,
    user: user,
    loggedIn: loggedIn,
    authorized: authorized,
    signIn: signIn,
    signOut: signOut,
    loginListener: loginListener
  }

  return (
    <GAuthContext.Provider value={authObj}>
      <Router>
        <Switch>
          <Route path='/' exact>
            <Header />
            <div className="content">
              <Home />
            </div>
          </Route>
          <Route path='/projekte' exact>
            <Header />
            <div className="content">
              <Projects />
            </div>
          </Route>
          <Route path='/lebenslauf' exact>
            <Header />
            <div className="content">
              <Vida />
            </div>
          </Route>
          <Route path='/staerken' exact>
            <Header />
            <div className="content">
              <Strengths />
            </div>
          </Route>
          <Route path='/ueber-mich' exact>
            <Header />
            <div className="content">
              <AboutMe />
            </div>
          </Route>
          <Route path='/kontakt' exact>
            <Header />
            <div className="content">
              <Contact />
            </div>
          </Route>
          <Route path='/starting-page' exact>
            {!loading && <PrivateRoute path="/starting-page"><StartingPage /></PrivateRoute>}
          </Route>
          <Route path='/testing-page' exact>
            <Header />
            <div className="content">
              <TestPage />
              {!loading && <PrivateRoute path="/testing-page"><TestPage /></PrivateRoute>}
            </div>
          </Route>
          <Route path='/*'>
            <Header />
            <div className="content">
              <NoSiteMatch />
            </div>
          </Route>
        </Switch>
      </Router>
      <div className="content">{!authorized && !loading && <UnauthorizedContent />}</div>
    </GAuthContext.Provider>
  );
}

export default App;

function Home() {
  return <h1>Jonas Schiano</h1>;
}

function Projects() {
  return <h1>Projekte</h1>
}

function Vida() {
  return <h1>Lebenslauf</h1>
}

function Strengths() {
  return <h1>Stärken</h1>
}

function AboutMe() {
  return <h1>Über mich</h1>
}

function Contact() {
  return <h1>Kontakt</h1>
}

function UnauthorizedContent() {

  useEffect(() => {
    document.title = "Nicht verfügbar"
  }, [])

  return <article>Moin moin. Es freut mich, dass du den Weg zu meiner Website gefunden hast. Leider befindet sich diese noch im Aufbau und steht Außenstehenden deshalb noch nicht zur Verfügung. <br /><br />Schaue doch einfach später nochmal vorbei oder schreibe mir eine Email:
    <br /><br />
    <button onClick={() => window.open("mailto:jonas@schiano.dev", "_blank")} style={{ margin: "0 auto", display: "block" }}>Email schreiben</button>
  </article>
}